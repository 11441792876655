var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('v-col',{staticClass:"justify-start",attrs:{"sm":"4","md":"3","lg":"2","xl":"2"}},[_c('FormButton',{attrs:{"text":"Voltar","type":"default","full":""},on:{"click":_vm.onGoBack}})],1),_c('v-col',{attrs:{"sm":"4","md":"3","lg":"2","xl":"2"}},[_c('FormButton',{attrs:{"text":"Novo endereço","type":"primario","full":""},on:{"click":_vm.onNew}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
          (_vm.cliente ? _vm.cliente.cpf_cnpj.length > 12 : false) &&
            _vm.addressLength == 0
        ),expression:"\n          (cliente ? cliente.cpf_cnpj.length > 12 : false) &&\n            addressLength == 0\n        "}],attrs:{"sm":"4","md":"3","lg":"2","xl":"2"}},[_c('FormButton',{attrs:{"text":"Buscar endereço","type":"warning","full":""},on:{"click":_vm.forceCreateAddress}})],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortDesc":true,"sortBy":"name","search":""},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"primario",fn:function(ref){
        var row = ref.row;
        var col = ref.col;
return [(col)?_c('Chip',{attrs:{"color":"green","text":"Sim","small":true}}):_vm._e(),(!col)?_c('Chip',{attrs:{"color":"cyan","text":"Não","small":true}}):_vm._e()]}},{key:"tipo",fn:function(ref){
        var row = ref.row;
        var col = ref.col;
return [_c('Chip',{attrs:{"color":_vm.getTipoColor(col),"text":col ? col : 'MANUAL',"small":true}})]}},{key:"origem",fn:function(ref){
        var row = ref.row;
        var col = ref.col;
return [_c('Chip',{attrs:{"color":_vm.getOrigemColor(col),"text":col ? col : 'MANUAL',"small":true}})]}},{key:"idendereco",fn:function(ref){
        var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.getTableOptions(row)},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }