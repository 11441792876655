<template>
  <div class="page">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <v-col class="justify-start" sm="4" md="3" lg="2" xl="2">
          <FormButton text="Voltar" type="default" full @click="onGoBack" />
        </v-col>
        <v-col sm="4" md="3" lg="2" xl="2">
          <FormButton
            text="Novo endereço"
            type="primario"
            full
            @click="onNew"
          />
        </v-col>
        <v-col
          sm="4"
          md="3"
          lg="2"
          xl="2"
          v-show="
            (cliente ? cliente.cpf_cnpj.length > 12 : false) &&
              addressLength == 0
          "
        >
          <FormButton
            text="Buscar endereço"
            type="warning"
            full
            @click="forceCreateAddress"
          />
        </v-col>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        :sortDesc="true"
        ref="datatable"
        sortBy="name"
        search
        @click="onClick"
      >
        <template v-slot:primario="{ row, col }">
          <Chip v-if="col" color="green" text="Sim" :small="true" />
          <Chip v-if="!col" color="cyan" text="Não" :small="true" />
        </template>
        <template v-slot:tipo="{ row, col }">
          <Chip
            :color="getTipoColor(col)"
            :text="col ? col : 'MANUAL'"
            :small="true"
          />
        </template>
        <template v-slot:origem="{ row, col }">
          <Chip
            :color="getOrigemColor(col)"
            :text="col ? col : 'MANUAL'"
            :small="true"
          />
        </template>
        <template v-slot:idendereco="{ row }">
          <FormButtonMenu
            icon
            :options="getTableOptions(row)"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import Panel from '@/components/Panel'
import Chip from '@/components/Chip'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
import {
  ADDRESS_REMOVE,
  CLIENTE_LOAD,
  ADDRESS_FORCE_CREATE
} from '@/store/actions/cliente'
const ORIGEM_TYPE = { DADOS_RECEITA: 'DADOS_RECEITA' }

export default {
  name: 'ClienteEnderecos',
  components: { Chip, DataTable, Panel, FormButton, FormButtonMenu },
  data: () => ({
    headers: [
      { text: 'Endereço', value: 'textoendereco' },
      {
        text: 'Cep',
        align: 'center',
        value: 'cep'
      },
      {
        text: 'Primário',
        align: 'center',
        value: 'primario'
      },
      {
        text: 'Tipo',
        align: 'center',
        value: 'tipo'
      },
      {
        text: 'Origem',
        align: 'center',
        value: 'origem'
      },
      {
        text: 'Opções',
        value: 'idendereco',
        align: 'center',
        clickable: false
      }
    ],
    cliente: null,
    addressLength: null
  }),
  created() {
    setTimeout(() => {
      let idcliente = this.$route.params.idclient
      if (!idcliente) {
        this.$router.replace('/fornecedor/cadastro/cliente')
      } else {
        this.$store
          .dispatch(CLIENTE_LOAD, { idcliente })
          .then(cliente => {
            this.cliente = cliente
            this.reloadDatatable()
          })
          .catch(() => {
            this.$router.replace('/fornecedor/cadastro/cliente')
          })
      }
    }, 1000)
  },
  computed: {
    ...mapGetters(['getFornecedorId']),
    endpoint() {
      return this.getFornecedorId
        ? `/api/v4/client/${this.$route.params.idclient}/address/datatable/${this.getFornecedorId}`
        : null
    },
    clientName() {
      return _.get(this.cliente, 'name') || ''
    }
  },
  methods: {
    getTableOptions(item) {
      const opt = []
      if (item.origem !== ORIGEM_TYPE.DADOS_RECEITA) {
        opt.push({ text: 'Atualizar', value: 'update' })
      }
      opt.push({ text: 'Deletar', value: 'delete' })
      return opt
    },
    getOrigemColor(col) {
      switch (col) {
        case ORIGEM_TYPE.DADOS_RECEITA:
          return 'auxiliar'
        default:
          return 'informacao'
      }
    },
    getTipoColor(col) {
      switch (col) {
        case 'ENTREGA':
          return 'primario'
        default:
          return 'purple'
      }
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'update':
          return this.onUpdate(item)
        case 'delete':
          return this.onDelete(item)
      }
    },
    onGoBack() {
      this.$router.replace({
        path: '/fornecedor/cadastro/cliente',
        query: this.$route.query
      })
    },
    onNew() {
      this.$router.push({
        path: 'endereco/novo',
        query: this.$route.query
      })
    },
    onClose() {
      this.$refs.datatable && this.reloadDatatable()
    },
    onClick(item) {
      this.onUpdate(item)
    },
    reloadDatatable() {
      this.$refs.datatable.reload()

      setTimeout(() => {
        this.addressLength = parseInt(this.$refs.datatable.options.itemsLength)
      }, 500)
    },
    onDelete(item) {
      const options = {
        title: `Remover o endereço?`,
        text: 'Essa ação é irreversível'
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    onUpdate(item) {
      this.$router.push({
        path: `endereco/${item.idendereco}`,
        query: this.$route.query
      })
    },
    delete(item) {
      this.$store
        .dispatch(ADDRESS_REMOVE, { idendereco: item.idendereco })
        .then(() => {
          this.$vueOnToast.pop('success', 'Cliente removido com sucesso')
          this.reloadDatatable()
        })
    },
    forceCreateAddress() {
      this.$store
        .dispatch(ADDRESS_FORCE_CREATE, { idcliente: this.cliente.idcliente })
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Endereço do cliente foi buscado e cadastrado com sucesso'
          )
          this.reloadDatatable()
        })
    }
  }
}
</script>
